export const PageLoad = {
  in: {
    opacity: 0
  },
  out: {
    opacity: 1
  }
}

export const Transition = {
  duration: 0.5
}